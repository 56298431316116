import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingState } from 'src/types/loading-state';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./login.scss'],
})
export class ForgotPasswordComponent {
  constructor(private fb: FormBuilder, private http: HttpClient, private r: Router) {}

  LoadingState = LoadingState;
  loadingState = LoadingState.SUCCESS

  forgotPassword = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
  });

  get email() {
    return this.forgotPassword.get('email');
  }

  handleSubmit() {
    this.loadingState = LoadingState.LOADING;
    this.http.post("/agetoage/service/login/password-reset-request", {
      email: this.email.value,
    })
      .toPromise()
      .then(() => {
        this.r.navigate(["./login"], {
          replaceUrl: true,
          queryParams: { action: "password_reset_request" }
        })
      })
      .catch((e) => {
        this.loadingState = LoadingState.FAILED;
        console.error(e)
      })
  }
}
