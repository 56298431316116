import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingState } from 'src/types/loading-state';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./login.scss']
})
export class AccountActivationComponent {
  constructor(private fb: FormBuilder, private http: HttpClient, private r: Router) {}

  LoadingState = LoadingState;
  loadingState = LoadingState.SUCCESS

  activate = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
  });

  get email() {
    return this.activate.get('email');
  }

  handleSubmit() {
    this.loadingState = LoadingState.LOADING;
    this.http.post("/agetoage/service/login/activate/resend-email", {
      email: this.email.value,
    })
      .toPromise()
      .then(() => {
        this.r.navigate(["./login"], {
          replaceUrl: true,
          queryParams: { action: "resend_activation_email" }
        })
      })
      .catch((e) => {
        this.loadingState = LoadingState.FAILED;
        console.error(e)
      })
  }

}
