import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

import { HomeComponent } from './home/home.component';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { RequestsComponent } from './requests/requests.component';
import { ResetPasswordComponent } from './login/reset-password.component';
import { CreateAccountComponent } from './login/create-account.component';
import { AccountActivationComponent } from './login/account-activation.component';
import { PdfViewerComponent } from './display-pdfs/pdfviewer.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        children: [{ path: ':id', component: HomeComponent }],
      },
      { path: 'requests', component: RequestsComponent },
      { path: 'disclaimer', component: PdfViewerComponent },
      { path: 'faqs', component: PdfViewerComponent },
      { path: 'privacypolicy', component: PdfViewerComponent },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
    ],
  },
  {
    path: 'login',
    children: [
      { path: '', component: LoginComponent },
      { path: 'create-account', component: CreateAccountComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'activate', component: AccountActivationComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
